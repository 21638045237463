import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Typo } from '../../utilities/CustomComponents';
import { MdAddCircle as AddIcon, MdCancel as DeleteIcon } from 'react-icons/md';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PrintIcon from '@mui/icons-material/Print';
import useStyles from './SectionContent.styles';
import StickyNoteIcon from '@mui/icons-material/StickyNote2';
import useWindowDimensions, { MOBILE_SIZE } from '../../hooks/useWindowDimensions';

const SectionContent = (props) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= MOBILE_SIZE;
  const classes = useStyles(props.bgcolor, props.isPrintBtnDisabled, isMobile);
  return (
    <Box sx={classes.container}>
      <Box sx={classes.titleWrapper}>
        <Typo sx={props.titleStyle ? props.titleStyle : classes.title} variant={'body1'}>
          {props.title}
        </Typo>
        <Box sx={classes.btnsWrapper} gap={'8px'}>
          {props.withNotes && !props.readOnlyNotes && (
            <Box
              sx={classes.editBtnContainer}
              onClick={props.addNotes ? () => props.addNotes() : undefined}>
              Add notes <StickyNoteIcon fontSize='small' sx={{ height: '14px' }} />
            </Box>
          )}
          {props.printBtn && (
            <Box
              sx={classes.printBtnContainer}
              hidden={!props.isEdit}
              onClick={
                !props.isPrintBtnDisabled && props.onClickPrint ? () => props.onClickPrint() : null
              }>
              {props.printBtnTitle}{' '}
              {
                <PrintIcon
                  fontSize='small'
                  sx={{ color: props.isPrintBtnDisabled ? '#C2C2C2' : '#707070' }}
                />
              }
            </Box>
          )}
          {props.showDelBtn && (
            <Box sx={classes.deleteBtnContainer} onClick={props.onClickDelete}>
              {props.btnDelTitle ? props.btnDelTitle : 'Delete'}
              <DeleteIcon fontSize='small' />
            </Box>
          )}
          {props.editBtn ? (
            <Box
              sx={props.editBtnContainer ? props.editBtnContainer : classes.editBtnContainer}
              hidden={!props.isEdit}
              onClick={() => props.onClick()}>
              {!isMobile && props.btnTitle}
              {props.isAddIcon ? (
                <AddIcon fontSize={isMobile ? 'large' : 'small'} />
              ) : (
                <EditNoteIcon fontSize='small' />
              )}
            </Box>
          ) : (
            <Box hidden={!props.isEdit} sx={classes.btn} onClick={() => props.onClick()}>
              New entry <AddIcon />
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={props.childStyle ? props.childStyle : classes.wrapper}>{props.children}</Box>
    </Box>
  );
};

SectionContent.propTypes = {
  title: PropTypes.string.isRequired,
  showDelBtn: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  isAddIcon: PropTypes.bool,
  onClick: PropTypes.func,
  onClickPrint: PropTypes.func,
  onClickDelete: PropTypes.func,
  bgcolor: PropTypes.string,
  childStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  editBtnContainer: PropTypes.object,
  btnTitle: PropTypes.string,
  btnDelTitle: PropTypes.string,
  printBtnTitle: PropTypes.string,
  isPrintBtnDisabled: PropTypes.bool,
  editBtn: PropTypes.bool,
  printBtn: PropTypes.bool,
  withNotes: PropTypes.bool,
  readOnlyNotes: PropTypes.bool,
  addNotes: PropTypes.func,
};

SectionContent.defaultProps = {
  showDelBtn: false,
  editBtn: false,
  isAddIcon: false,
  withNotes: false,
  readOnlyNotes: true,
};

export default SectionContent;
