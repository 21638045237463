/**
 * Contains logic for uploading and downloading files using the FileLibrary App.
 */

import axios from 'axios';

const BUCKET = 'fallbackbucket';

const getPresignedPostData = async (bucket, key) => {
  return new Promise(resolve => {
    const accessToken = sessionStorage.getItem('userAuthCreds') &&
        JSON.parse(sessionStorage.getItem('userAuthCreds')).id
    const apiClient = axios.create()
    apiClient.interceptors.request.use((config) => ({
      ...config,
      'params': {
        'access_token': accessToken,
        ...config.params
      },
      'headers': {
        'Authorization': accessToken
      }
    }))
    const url = `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/filelibrary/createPresignedPost?access_token=${accessToken}`;

    apiClient.post(url, {
      bucket: bucket,
      key: key,
    })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          return;
        });
  });
};

const getPresignedGetData = async (bucket, key) => {
  return new Promise(resolve => {
    const accessToken = sessionStorage.getItem('userAuthCreds') &&
        JSON.parse(sessionStorage.getItem('userAuthCreds')).id
    const apiClient = axios.create()
    apiClient.interceptors.request.use((config) => ({
      ...config,
      'params': {
        'access_token': accessToken,
        ...config.params
      },
      'headers': {
        'Authorization': accessToken
      }
    }))
    const url = `${process.env.REACT_APP_SLS_LOOPBACK_API_ENDPOINT}/api/filelibrary/createPresignedGet?access_token=${accessToken}`;

    apiClient.post(url, {
      bucket: bucket,
      key: key,
    })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          return;
        });
  });
};

// Parses Filelibrary v1 link to get the bucket and key for S3
function parseV1Link(uri) {
  // Format is /api/containers/<bucket>/download/<userID>%2F<filename>
  let split = uri.split('/');
  return {
    bucket: split[3],
    key: decodeURIComponent(split[5]),
  };
}

/**
 * Uploads new Files after the File Uploader Input Changes.
 * @param {FileList} files List of files to upload
 * @param {Function} progressUpdateFunc Function used to update the progress bar
 * @returns Returns the new FileObjects to Concatenate with teh existing ones
 */
export async function uploadFiles(files, progressUpdateFunc) {
  let size = 0;
  for (let i = 0; i < files.length; i++) {
    size += files[i].size;
  }
  let sizeObject = {}; // Used for keeping track of Uploaded File Progress
  let fileObjs = [];

  for (let i = 0; i < files.length; i++) {
    let file = files[i];
    let fileKey = '' + new Date().getTime() + '__' + file.name;
    const presignedPostData = await getPresignedPostData(BUCKET, fileKey);
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach(key => {
      formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);
    try {
      const response = await axios.post(presignedPostData.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          // Calculate the Progress here
          if (!sizeObject[fileKey]) sizeObject[fileKey] = 0;
          sizeObject[fileKey] = loaded;
          let loadedSize = 0;
          // Calculate total loaded size
          //Object.keys(sizeObject).forEach((file) => (loaded += sizeObject[file]));
          if (progressUpdateFunc) {
            progressUpdateFunc(loaded / total);
          }
        },
      });
      //console.log(`Upload successful for ${files[i].name}:`, response.data);
      let fileObj = {
        key: fileKey,
        size: file.size,
        type: file.type,
        name: file.name,
        deleted: false,
        associated: false
      };
      const presignedGetData = await getPresignedGetData(BUCKET, fileKey);
      fileObj.link = presignedGetData;
      fileObj.downloadLink = presignedGetData;
      fileObjs.push(fileObj);
    } catch (error) {
      console.error(`Upload error for ${files[i].name}:`, error);
      return [];
    }
  }
  return fileObjs;
}

/**
 * This removes the Date and DUMMYSTRING from the file so the user gets their regular filename back
 * @param {Object} payload The object in which the filename possibly containing the dummystring (Older files won't)
 * @returns {String} The filename without the key padding string (Or with it if it's an older file)
 */
export function _formatFileNameBeforeDownload(payload) {
  let name = payload.pvKey;
  let filename = '';
  //splice out the dummy string if it exists (If it doesn't it's an old file)
  if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
    filename = name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
  }

  if (name && name.indexOf('%2F') >= 0) {
    filename = name.substring(name.indexOf('%2F') + 3);
  }

  // this means there was no title entered when the file was uploaded
  if (filename === payload.pvName) {
    let ext = filename.substring(filename.lastIndexOf('.') + 1);
    let filename_without_ext = filename.substring(0, filename.lastIndexOf('.'));
    let filename_without_ts = filename_without_ext.substring(
      0,
      filename_without_ext.lastIndexOf('_'),
    );

    return filename_without_ts.length ? filename_without_ts : filename_without_ext + '.' + ext;
  } else {
    // this means the file name was entered, so use that
    let ext = filename.substring(filename.lastIndexOf('.') + 1);
    return payload.pvName + '.' + ext;
  }
}

export function downloadFile(fileObj) {
  let payload = fileObj;
  let a = document.createElement('a');
  a.href = fileObj.link;
  a.target = '_blank';
  a.download = _formatFileNameBeforeDownload(payload);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// Adds the link to all files
export async function getFiles(uploadedFiles) {
  let funcs = [];
  uploadedFiles.forEach((fileObj) => {
    if (!fileObj.pvKey) {
      return;
    }
    let keybucket;
    if (fileObj.v2 !== 1) {
      // This is a v1 file, parse the link
      keybucket = parseV1Link(fileObj.pvKey);
    } else {
      keybucket = {
        key: fileObj.pvKey,
        bucket: BUCKET,
      };
    }
    funcs.push(getLink(fileObj));
  });
  await Promise.all(funcs);
  return uploadedFiles;
}

export async function getLink(fileObj) {
  if (!fileObj.pvKey) {
    return;
  }
  const presignedGetData = await getPresignedGetData(BUCKET, fileObj.pvKey);
  fileObj.link = presignedGetData;
  return fileObj;
}

// ===== Helper Functions ===== //

/**
 * Returns number of uploaded files
 * @param {Array} uploadedFiles Array of Uploaded File Objects
 */
export function getNumOfFiles(uploadedFiles) {
  let toRet = 0;
  uploadedFiles.forEach((file) => {
    if (!file.deleted) toRet++;
  });
  return toRet;
}

// Gets the actual filename from the key
export function getProperKeyName(key) {
  if (key) {
    let a = key.substring(key.indexOf('/') + 2);
    return a.substring(a.indexOf('__') + 2, a.length);
  }

  return key;
}

export const getProperKeyNameDummySearchString = (name) => {
  if (name && name.indexOf('_DUMMYSEARCHSTRING_') >= 0) {
    return name.substring(name.indexOf('_DUMMYSEARCHSTRING_') + 19);
  }
  return name;
};

/**
 * Returns the Size of the file from a bytes value
 * @param {Number} bytes Number of Bytes
 */
export function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  // eslint-disable-next-line
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

